import ErrorStatusPage from '@otovo/shared/components/ErrorStatusPage/ErrorStatusPage';
import {
  Otovo$Config,
  Otovo$StorefrontConfig,
} from '@otovo/shared/types/otovoweb';

import {
  GetStaticPropsReturnType,
  StaticPropsContext,
} from '@otovo/shared/lib/next/types';
import StorefrontPage from '../components/StorefrontPage';
import { getStaticProps as getStaticMarketProps } from '../config/getProps';

type Props = {
  config: Otovo$Config;
};

const Error404 = ({ config }: Props) => {
  return (
    <StorefrontPage config={config}>
      <ErrorStatusPage status={404} />
    </StorefrontPage>
  );
};

export async function getStaticProps(
  context: StaticPropsContext,
): Promise<GetStaticPropsReturnType<Otovo$StorefrontConfig>> {
  const { props } = await getStaticMarketProps(context);
  return {
    props,
  };
}
export default Error404;
